<template>
  <b-modal
    visible
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="">
      <div class="d-flex">
        <b-input
          v-model="name"
          @input="deferredRequest"
          @keyup.native.enter="fetchTemplates"
        />

        <b-button
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          title="Поиск"
          class="ml-2"
          @click="fetchTemplates"
        >
          <b-icon icon="search" />
        </b-button>
      </div>

      <div
        v-if="isLoading"
        class="d-flex justify-content-center w-100 mt-3"
      >
        <b-spinner
          size="sm"
          variant="primary"
        />
      </div>
      <template v-else>
        <div
          v-for="templateItem in templates"
          :key="templateItem.id"
          class="template-item"
        >
          <div class="template-item__title d-flex mb-2 bg-white">
            <div
              class="d-flex justify-content-between align-items-center bg-primary rounded p-2 w-100"
              @click="templateItem.showContent = !templateItem.showContent"
            >
              <div class="text-white">
                {{ templateItem.name }}
              </div>

              <b-icon
                v-if="type !== 'RejectionReason'"
                icon="caret-down-fill"
                variant="light"
                :rotate="templateItem.showContent ? 180 : 0"
              />
            </div>

            <b-button
              variant="primary"
              :type="$const.PRIMARY_BUTTON"
              title="Изменить"
              size="sm"
              class="ml-2"
              @click="editAppealTemplate(templateItem)"
            >
              <b-icon icon="pencil-square" />
            </b-button>
            <b-button
              variant="danger"
              :type="$const.PRIMARY_BUTTON"
              title="Удалить"
              size="sm"
              class="ml-2"
              @click="agreeRemoveTemplate(templateItem.id)"
            >
              <b-icon icon="x" />
            </b-button>
            <b-button
              variant="success"
              :type="$const.PRIMARY_BUTTON"
              title="Выбрать"
              size="sm"
              class="ml-2"
              @click="selectTemplate(templateItem)"
            >
              <b-icon icon="check-circle" />
            </b-button>
          </div>

          <transition name="fade">
            <div
              v-if="templateItem.showContent && type !== 'RejectionReason'"
              class="p-1"
            >
              <!-- <div class="mb-2">
                <div class="mb-1">
                  Диагноз
                </div>

                {{ templateItem.parsedData.reconciliationsDiagnosis || '-' }}
              </div> -->
              <!-- <div class="mb-2">
                <div class="mb-1">
                  ФИО Врача
                </div>

                {{ templateItem.parsedData.reconciliationsDoctorName || '-' }}
              </div> -->
              <!-- <div class="mb-2">
                <div class="mb-1">
                  Согласованные услуги
                </div>

                {{ templateItem.parsedData.reconciliationsAgreedServices || '-' }}
              </div> -->
              <!-- <div class="mb-2">
                <div class="mb-1">
                  Несогласованные услуги
                </div>

                {{ templateItem.parsedData.reconciliationsNotAgreedServices || '-' }}
              </div> -->
              <div class="mb-3 block-title">
                <h5 class="mt-2">
                  Причина отказа
                </h5>
                <div class="block-divider" />
                <div class="block-text">
                  {{ templateItem.parsedData.reconciliationsRegectionReason || '-' }}
                </div>
              </div>
              <div class="mb-2 block-title">
                <h5 class="mt-2">
                  Комментарий врача
                </h5>
                <div class="block-divider" />
                <div class="block-text">
                  {{ templateItem.parsedData.reconciliationsDoctorComment || '-' }}
                </div>
              </div>
            </div>
          </transition>
        </div>
      </template>
    </div>

    <template #modal-footer>
      <b-button
        variant="success"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="createAppealTemplate"
      >
        Создать шаблон
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <!-- <b-button
        variant="primary"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button> -->
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { appealsTemplatesService } from '@/services';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'AppealTemplatesModal',
  components: {
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Выберите шаблон согласования',
    },
    reconciliation: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'Reconciliation',
    },
  },

  data: () => ({
    isOpen: true,
    isLoading: false,
    isSaving: false,

    templates: [],

    name: '',
    take: 25,
    skip: 0,
    isActive: true,
    deferred: null,
  }),

  computed: {
    ...mapGetters({
      fetchAppealsTemplatesTrigger: 'AppealsTemplates/FETCH_TRIGGER_TEMPLATES_APPEALS',
      fetchAppealsTemplatesRejectTrigger: 'AppealsTemplates/FETCH_TRIGGER_TEMPLATES_APPEALS_REJECT',
    }),
    changeTitle() {
      if (this.type === 'RejectionReason') {
        return 'Создание шаблона отказа от МиД';
      }
      return 'Создание шаблона согласования';
    },
  },

  watch: {
    async fetchAppealsTemplatesTrigger() {
      await this.fetchTemplates();
    },
    async fetchAppealsTemplatesRejectTrigger() {
      await this.fetchTemplates();
    },
  },

  async mounted() {
    this.fetchTemplates();
  },

  methods: {
    onClose() {
      this.$emit('input', false);
    },
    deferredRequest() {
      clearTimeout(this.deferred);

      this.deferred = setTimeout(() => {
        this.fetchTemplates();
      }, 1200);
    },
    editAppealTemplate(templateData) {
      uiService.showModal(MODALS.APPEAL_TEMPLATE_EDIT_MODAL, {
        name: 'AppealTemplateEditModal',
        props: {
          templateData,
          appealType: this.type,
          title: this.changeTitle,
        },
      });
    },
    createAppealTemplate() {
      uiService.showModal(MODALS.APPEAL_TEMPLATE_EDIT_MODAL, {
        name: 'AppealTemplateEditModal',
        props: {
          appealType: this.type,
          title: this.changeTitle,
        },
      });
    },
    selectTemplate(selectedData) {
      if (this.type !== 'RejectionReason') {
        uiService.showModal(MODALS.AGREE_MODAL, {
          name: 'AgreeModal',
          props: {
            title: 'Подтвердите выбранный шаблон',
            closeAnotherModalName: this.modalName,
          },
          actions: {
            delete: async () => {
              try {
                this.$emit('onSelectAppealTemplate', selectedData.parsedData, this.reconciliation);
              } catch (err) {
                console.log(err);
              } finally {
                this.onClose();
              }
            },
          },
        });
      } else {
        this.$emit('onSelectAppealTemplateForReject', selectedData.parsedData);
        this.onClose();
      }
    },
    async fetchTemplates() {
      clearTimeout(this.deferred);
      this.isLoading = true;

      try {
        const data = await appealsTemplatesService.get({
          name: this.name,
          take: this.take,
          skip: this.skip,
          isActive: this.isActive,
          type: this.type,
        });
        if (data && Array.isArray(data) && data.length) {
          this.templates = data.map((item) => {
            let parsedData;
            try {
              parsedData = JSON.parse(item.text);
            } catch (error) {
              parsedData = item.text;
            }

            return {
              ...item,
              parsedData,
              showContent: false,
            };
          });
        }
        if (data.length === 0) {
          this.templates = data;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async removeTemplate(id) {
      try {
        await appealsTemplatesService.delete(id);
        if (this.type === 'RejectionReason') {
          this.$store.commit('AppealsTemplates/TOGGLE_FETCH_APPEALS_TEMPLATES_REJECT_TRIGGER');
        } else {
          this.$store.commit('AppealsTemplates/TOGGLE_FETCH_APPEALS_TEMPLATES_TRIGGER');
        }
      } catch (e) {
        console.error(e);
      }
    },
    agreeRemoveTemplate(id) {
      uiService.showModal(MODALS.AGREE_MODAL, {
        name: 'AgreeModal',
        props: {
          title: 'Удаление шаблона',
        },
        actions: {
          delete: async () => {
            try {
              await this.removeTemplate(id);
            } catch (err) {
              console.log(err);
            }
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.template-item {
  position: relative;

  &:not(:first-child) {
    margin-top: 1.2rem;
  }

  &__title {
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &::before {
      position: absolute;
      content: '';
      transform: translate(0, -100%);
      left: 0;
      top: 0;
      height: 17px;
      width: 100%;
      background-color: #fff;
      z-index: -1;
    }
  }
}

.block-title {
  padding: 5px;
  background-color: #f8f9fa87;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.block-text {
  margin-bottom: 5px;
}

.block-divider {
  display: block;
  border-radius: 4px;
  height: 3px;
  margin: 15px auto 10px;
  background: rgb(199, 199, 199);
}

.fade-enter-active,
.fade-leave-active {
  transition: all .6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
